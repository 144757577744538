import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuardServiceGuard implements CanActivate {
  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router
  ) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (state.url === '/') {
        console.log("true");
        this.router.navigate([state.url], {
          queryParams: {
            package: 'pro',
            duration: 'monthly'
          },
          relativeTo: this.activatedRoute
        });
        return false;
      }
      return true;
  }
  
}
