import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {CheckoutGuardServiceGuard} from './guard/checkout-guard-service.guard'
const routes: Routes = [
    {
      path:'',
      loadComponent: () => import('libs/checkout/ui/src/lib/layout/layout.component').then(mod => mod.LayoutComponent),
      children: [
        {
          path: '',
          loadComponent: () => import('@penji/checkout/pages/home-page').then(mod => mod.CheckoutAddonsComponent),
          canActivate: [CheckoutGuardServiceGuard]
        }
      ]
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    
  })],
  exports: [RouterModule]
})
export class CheckoutShellRoutingModule { }